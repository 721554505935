
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$unos-sienna-poc-primary: mat.define-palette(mat.$indigo-palette);
$unos-sienna-poc-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$unos-sienna-poc-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$unos-sienna-poc-theme: mat.define-light-theme((
  color: (
    primary: $unos-sienna-poc-primary,
    accent: $unos-sienna-poc-accent,
    warn: $unos-sienna-poc-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($unos-sienna-poc-theme);

// Use the default configuration.
$my-typography: mat.define-typography-config();
@include mat.typography-hierarchy($my-typography);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background: linear-gradient(180deg, #f2fbff, #f2fbff) !important; }

.model-annotation {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-left: 15px;
  margin-top: 15px;
  padding: 1em;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: .5em;
  font-size: 12px;
  line-height: 1.2;
  transition: opacity .5s;
  // max-width: 200px;
  white-space: pre-wrap;
  word-wrap: break-word;

  .blinking-cursor {
    color: #fff;
    animation: 1s blink step-end infinite;
  }

  &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border: 2px solid red;
      border-radius: 50%;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      background: rgba(0, 0, 0, 0.8);
  }

  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #fff;
    }
  }
}

.mdc-text-field--filled{
  background-color: transparent !important;
}

.mat-mdc-dialog-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  .mdc-dialog__container {
    min-width: 280px !important;
    max-width: 80% !important;
    max-height: 80% !important;
    margin: auto !important;

    .mdc-dialog__surface {
      max-height: unset;
      min-height: unset;
      min-width: unset;
      max-width: unset;
    }

    &.fullscreen {
      height: 100% !important;
      width: 100% !important;
      max-height: unset !important;
      max-width: unset !important;

      modal-img-preview {
        display: flex;
        flex-direction: column;
        height: 100%;
        
        .mat-mdc-dialog-content {
          height: 100% !important;
          max-height: unset !important;

          .image-container {
            height: 100% !important;
            width: 100% !important;
          }

          canvas {
            height: 100% !important;
            width: 100% !important
          }
        }
      }

    }
  }
}